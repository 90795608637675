// store/features/beams/beamsSlice.ts
import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/types/state';
import type { Beam } from '@/types/entities';
import { Support } from '@/types';
import type { SectionProperties } from '@/types/sections';
import { createInitialBeam } from '@/store/shared/constants';
import { PointLoad, LineLoad } from '@/types/loads';

export const beamsAdapter = createEntityAdapter<Beam>({
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

interface AddSupportPayload {
  beamId: string;
  support: Support;
}

interface RemoveSupportPayload {
  beamId: string;
  supportIndex: number;
}
interface UpdateSectionPropertiesPayload {
  beamId: string;
  properties: Partial<SectionProperties>;
}

interface UpdateBeamLengthPayload {
  beamId: string;
  length: number;
}

interface AddPointLoadPayload {
  beamId: string;
  load: PointLoad;
}

interface AddLineLoadPayload {
  beamId: string;
  load: LineLoad;
}

interface RemoveLoadPayload {
  beamId: string;
  loadId: string;
}

// Create initial beam
const initialBeam = createInitialBeam();

const initialState = beamsAdapter.getInitialState();
const populatedInitialState = beamsAdapter.addOne(initialState, initialBeam);

export const beamsSlice = createSlice({
  name: 'beams',
  initialState: populatedInitialState,
  reducers: {
    addBeam: beamsAdapter.addOne,
    removeBeam: beamsAdapter.removeOne,
    updateBeam: beamsAdapter.updateOne,
    
    updateSectionProperties: (state, action: PayloadAction<UpdateSectionPropertiesPayload>) => {
      const { beamId, properties } = action.payload;
      const beam = state.entities[beamId];
      if (beam) {
        beam.sectionProperties = {
          ...beam.sectionProperties,
          ...properties
        };
      }
    },
    
    updateBeamLength: (state, action: PayloadAction<UpdateBeamLengthPayload>) => {
      const { beamId, length } = action.payload;
      const beam = state.entities[beamId];
      if (beam) {
        beam.length = length;
      }
    },

    resetBeam: (state, action: PayloadAction<string>) => {
      const beamId = action.payload;
      const newBeam = createInitialBeam();
      newBeam.id = beamId; // Keep same ID
      beamsAdapter.updateOne(state, {
        id: beamId,
        changes: newBeam
      });
    },
    addSupport: (state, action: PayloadAction<AddSupportPayload>) => {
      const { beamId, support } = action.payload;
      const beam = state.entities[beamId];
      
      if (beam) {
        // Sort supports by location to maintain order
        const newSupports = [...beam.supports, support]
          .sort((a, b) => a.location - b.location);
        
        beam.supports = newSupports;
      }
    },

    removeSupport: (state, action: PayloadAction<RemoveSupportPayload>) => {
      const { beamId, supportIndex } = action.payload;
      const beam = state.entities[beamId];
      
      if (beam && supportIndex >= 0 && supportIndex < beam.supports.length) {
        beam.supports.splice(supportIndex, 1);
      }
    },

    updateSupportLocation: (
      state,
      action: PayloadAction<{
        beamId: string;
        supportIndex: number;
        location: number;
      }>
    ) => {
      const { beamId, supportIndex, location } = action.payload;
      const beam = state.entities[beamId];
      
      if (beam && beam.supports[supportIndex]) {
        beam.supports[supportIndex].location = location;
        // Re-sort supports after update
        beam.supports.sort((a, b) => a.location - b.location);
      }
    },
    addPointLoad: (state, action: PayloadAction<AddPointLoadPayload>) => {
      const { beamId, load } = action.payload;
      const beam = state.entities[beamId];
      if (beam) {
        beam.pointLoads = [...beam.pointLoads, load]
          .sort((a, b) => a.location - b.location);
      }
    },

    removePointLoad: (state, action: PayloadAction<RemoveLoadPayload>) => {
      const { beamId, loadId } = action.payload;
      const beam = state.entities[beamId];
      if (beam) {
        beam.pointLoads = beam.pointLoads.filter(load => load.id !== loadId);
      }
    },

    addLineLoad: (state, action: PayloadAction<AddLineLoadPayload>) => {
      const { beamId, load } = action.payload;
      const beam = state.entities[beamId];
      if (beam) {
        beam.lineLoads = [...beam.lineLoads, load]
          .sort((a, b) => a.start_location - b.start_location);
      }
    },

    removeLineLoad: (state, action: PayloadAction<RemoveLoadPayload>) => {
      const { beamId, loadId } = action.payload;
      const beam = state.entities[beamId];
      if (beam) {
        beam.lineLoads = beam.lineLoads.filter(load => load.id !== loadId);
      }
    },
    
  }
});

export const {
  addBeam,
  removeBeam,
  updateBeam,
  updateSectionProperties,
  updateBeamLength,
  resetBeam,
  addSupport,
  removeSupport,
  updateSupportLocation,
  addPointLoad,
  removePointLoad,
  addLineLoad,
  removeLineLoad,
} = beamsSlice.actions;



export const selectBeamProperties = (state: RootState, beamId: string) => {
  const beam = selectBeamById(state, beamId);
  if (!beam) return null;
  
  return {
    length: beam.length,
    ...beam.sectionProperties
  };
};

export const selectBeamSupports = (state: RootState, beamId: string) => {
  const beam = state.beams.entities[beamId];
  return beam ? beam.supports : [];
};

export const selectHasMinimumSupports = (state: RootState, beamId: string) => {
  const beam = state.beams.entities[beamId];
  return beam ? beam.supports.length >= 2 : false;
};

// Validator function for support placement
export const validateSupportPlacement = (
  beam: Beam,
  location: number,
  existingIndex?: number
): { valid: boolean; message?: string } => {
  // Check if location is within beam length
  if (location < -1 || location > beam.length) {
    return {
      valid: false,
      message: `Location must be between 0 and ${beam.length}mm`
    };
  }

  // Check for overlapping supports (with tolerance)
  const hasOverlap = beam.supports.some((support, index) => {
    if (existingIndex !== undefined && index === existingIndex) {
      return false; // Skip the support being updated
    }
    const distance = Math.abs(support.location - location);
    return distance < 1; // 1mm tolerance
  });

  if (hasOverlap) {
    return {
      valid: false,
      message: "A support already exists at this location"
    };
  }

  return { valid: true };
};

// Selectors
export const {
  selectAll: selectAllBeams,
  selectById: selectBeamById,
  selectIds: selectBeamIds,
} = beamsAdapter.getSelectors<RootState>(state => state.beams);
export default beamsSlice.reducer;