import { Button } from '@/components/ui/button';
import { Download } from 'lucide-react';

const downloadFile = (content: string, filename: string, type: string) => {
  const blob = new Blob([content], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const DownloadButtons = ({ 
  svgContent, 
  results,
  className = ""
}: { 
  svgContent?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  results?: any;
  className?: string;
}) => {
  const handleSVGDownload = () => {
    if (!svgContent) return;
    downloadFile(svgContent, 'beam-visualization.svg', 'image/svg+xml');
  };

  const handleResultsDownload = () => {
    if (!results) return;
    const jsonStr = JSON.stringify(results, null, 2);
    downloadFile(jsonStr, 'beam-results.json', 'application/json');
  };

  return (
    <div className={`flex gap-2 ${className}`}>
      {svgContent && (
        <Button
          variant="outline"
          size="sm"
          onClick={handleSVGDownload}
          className="flex items-center gap-2"
        >
          <Download className="w-4 h-4" />
          Download SVG
        </Button>
      )}
      {results && (
        <Button
          variant="outline"
          size="sm"
          onClick={handleResultsDownload}
          className="flex items-center gap-2"
        >
          <Download className=" w-4 h-4" />
          Download Results
        </Button>
      )}
    </div>
  );
};