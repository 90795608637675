// store/features/ui/uiSlice.ts
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState, ValidationError } from '@/types/state';
import { createInitialBeam } from '@/store/shared/constants';

interface UiState {
  activeBeamId: string | null;
  selectedElementId: string | null;
  errors: ValidationError[];
  calculationStatus: 'idle' | 'loading' | 'success' | 'error';
}

const initialBeam = createInitialBeam();

const initialState: UiState = {
  activeBeamId: initialBeam.id, // Set initial beam as active
  selectedElementId: null,
  errors: [],
  calculationStatus: 'idle'
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveBeam: (state, action: PayloadAction<string | null>) => {
      state.activeBeamId = action.payload;
    },
    setSelectedElement: (state, action: PayloadAction<string | null>) => {
      state.selectedElementId = action.payload;
    },
    addError: (state, action: PayloadAction<ValidationError>) => {
      state.errors.push(action.payload);
    },
    clearErrors: (state) => {
      state.errors = [];
    },
    setCalculationStatus: (state, action: PayloadAction<UiState['calculationStatus']>) => {
      state.calculationStatus = action.payload;
    }
  }
});

export const {
  setActiveBeam,
  setSelectedElement,
  addError,
  clearErrors,
  setCalculationStatus
} = uiSlice.actions;

export const selectActiveBeamId = (state: RootState) => state.ui.activeBeamId;
export const selectSelectedElementId = (state: RootState) => state.ui.selectedElementId;
export const selectErrors = (state: RootState) => state.ui.errors;
export const selectCalculationStatus = (state: RootState) => state.ui.calculationStatus;

export default uiSlice.reducer;