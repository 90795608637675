/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import BeamVisualization from './BeamVis';
import { useAppSelector } from '@/store/hooks';
import PDFReport from './PdfReport';
import { DownloadButtons } from './Download';
import { 
  selectAnalysisStatus, 
  selectCalculations,
  selectSvgs 
} from '@/store/features/analysis/analysisSlice';
import { selectActiveBeamId } from '@/store/features/ui/uiSlice';
import { selectBeamById } from '@/store/features/beams/beamsSlice';
import LoadingSpinner from '@/components/common/LoadingSpinner';

const SVGDisplay = ({ svgString }: { svgString: string }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [scale, setScale] = React.useState(1);
  
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current) {
        const container = containerRef.current;
        const svg = container.querySelector('svg');
        if (svg) {
          const containerWidth = container.clientWidth;
          const svgWidth = svg.viewBox.baseVal.width;
          setScale(containerWidth / svgWidth);
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [svgString]);

  return (
    <div className="relative">
      <div 
        ref={containerRef} 
        className="w-full bg-white rounded-lg shadow-sm"
      >
        <div
          style={{
            transform: `scale(${scale / 1.4})`,
            transformOrigin: 'top left',
          }}
          dangerouslySetInnerHTML={{ __html: svgString }}
        />
      </div>
      <div className="absolute top-2 right-2">
        <DownloadButtons svgContent={svgString} />
      </div>
    </div>
  );
};

const ResultsTable = ({ data, title }: { data: any, title: string }) => (
  <div className="bg-white rounded-lg p-4 shadow-sm">
    <h3 className="text-lg font-medium mb-4">{title}</h3>
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          {/* Headers will be generated from data */}
          <tr>
            {Object.keys(Object.values(data)[0] || {}).map((header) => (
              <th 
                key={header}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Object.entries(data).map(([key, values]: [string, any]) => (
            <tr key={key}>
              {Object.values(values).map((value: any, index) => (
                <td 
                  key={index}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  {typeof value === 'number' ? value.toFixed(3) : value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export const DataVisualsPanel = () => {
  const activeBeamId = useAppSelector(selectActiveBeamId);
  const status = useAppSelector(selectAnalysisStatus);
  const calculations = useAppSelector(selectCalculations);
  const svgs = useAppSelector(selectSvgs);
  const beam = useAppSelector(state => 
    activeBeamId ? selectBeamById(state, activeBeamId) : null
  );

  if (!activeBeamId) {
    return (
      <Card className="h-full">
        <CardContent className="flex items-center justify-center h-full">
          <p className="text-sm text-muted-foreground">
            No beam selected
          </p>
        </CardContent>
      </Card>
    );
  }

  if (status === 'loading') {
    return (
      <Card className="h-full">
        <CardContent className="flex items-center justify-center h-full">
          <LoadingSpinner />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="h-full">
      <CardContent className="p-4">
        <Tabs defaultValue="model" className="h-full">
          <TabsList className="mb-4">
            <TabsTrigger value="model">Geometri</TabsTrigger>
            {status === 'succeeded' && (
              <>
                <TabsTrigger value="displacement">Deformation</TabsTrigger>
                <TabsTrigger value="moment">Bøjningsmoment</TabsTrigger>
                <TabsTrigger value="shear">Forskydningskraft</TabsTrigger>
                <TabsTrigger value="results">Resultater</TabsTrigger>
              </>
            )}
          </TabsList>

          <TabsContent value="model" className="space-y-4">
            <BeamVisualization />
          </TabsContent>

          {status === 'succeeded' && svgs && (
            <>
              <TabsContent value="displacement" className="space-y-4">
                {svgs?.displacement && (
                  <SVGDisplay svgString={svgs.displacement} />
                )}
                {calculations?.displacements && (
                  <ResultsTable 
                    data={calculations.displacements} 
                    title="Nodal Displacements" 
                  />
                )}
              </TabsContent>

              <TabsContent value="moment" className="space-y-4">
                {svgs?.bending_moment && (
                  <SVGDisplay svgString={svgs.bending_moment} />
                )}
                {calculations?.element_forces && (
                  <ResultsTable 
                    data={calculations.element_forces} 
                    title="Element Forces" 
                  />
                )}
              </TabsContent>

              <TabsContent value="shear" className="space-y-4">
                {svgs?.shear_force && (
                  <SVGDisplay svgString={svgs.shear_force} />
                )}
              </TabsContent>

              <TabsContent value="results" className="space-y-4">
                {calculations && (
                  <div className="space-y-4">
                    <div className="flex justify-end">
                      <DownloadButtons results={calculations} />
                    </div>
                    <ResultsTable 
                      data={calculations.reaction_forces} 
                      title="Reaction Forces" 
                    />
                    <ResultsTable 
                      data={calculations.displacements} 
                      title="Nodal Displacements" 
                    />
                    <ResultsTable 
                      data={calculations.element_forces} 
                      title="Element Forces" 
                    />
                  </div>
                  
                )}
              {beam && (
                <PDFReport 
                  calculations={calculations}
                  beamProperties={{ ...beam.sectionProperties, length: beam.length }}
                  svgs={svgs}
                />
              )}
              </TabsContent>
            </>
          )}
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default DataVisualsPanel;