// store/hooks.ts
import { 
    TypedUseSelectorHook, 
    useDispatch, 
    useSelector 
  } from 'react-redux';
  import type { RootState } from '$types/state';
  import type { AppDispatch } from './';
  
  // Use throughout your app instead of plain `useDispatch` and `useSelector`
  export const useAppDispatch: () => AppDispatch = useDispatch;
  export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;