import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"

const DocsPage = () => {

 

  return (
    <div className="min-h-screen bg-background py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8">Bjælke Beregner Dokumentation</h1>
        
       

        <Accordion type="multiple">
          <AccordionItem value="item-1">
            <AccordionTrigger>Kom i gang</AccordionTrigger>
            <AccordionContent>
              <p>For at begynde at bruge Bjælke Beregneren, følg disse trin:</p>
              <ol className="list-decimal list-inside mt-2 space-y-2">
                <li>Gå til beregningssiden ved at klikke på "Start beregning" på forsiden.</li>
                <li>Indtast bjælkens dimensioner (længde, bredde, højde).</li>
                <li>Vælg materialet for bjælken fra dropdown-menuen.</li>
                <li>Angiv belastningstype og værdi.</li>
                <li>Klik på "Beregn" for at se resultaterne.</li>
              </ol>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-2">
            <AccordionTrigger>Indtastning af data</AccordionTrigger>
            <AccordionContent>
              <p>Når du indtaster data i Bjælke Beregneren, skal du være opmærksom på følgende:</p>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>Alle længdemål skal angives i meter (m).</li>
                <li>Belastninger skal angives i kilonewton (kN) for punktlaster og kilonewton per meter (kN/m) for fordelte laster.</li>
                <li>Vær sikker på, at du vælger den korrekte belastningstype (punktlast eller fordelt last).</li>
                <li>Dobbelttjek dine indtastninger før du klikker på "Beregn" for at sikre nøjagtige resultater.</li>
              </ul>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-3">
            <AccordionTrigger>Fortolkning af resultater</AccordionTrigger>
            <AccordionContent>
              <p>Efter beregningen vil du se følgende resultater:</p>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>Maksimal nedbøjning: Den største udbøjning af bjælken under belastning.</li>
                <li>Maksimalt moment: Det største bøjningsmoment, der opstår i bjælken.</li>
                <li>Maksimal forskydningskraft: Den største forskydningskraft i bjælken.</li>
                <li>Spændingsfordeling: En visuel repræsentation af spændinger langs bjælken.</li>
              </ul>
              <p className="mt-2">
                Brug disse resultater til at vurdere, om bjælken er egnet til den givne belastning og om den overholder relevante sikkerhedsfaktorer.
              </p>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-4">
            <AccordionTrigger>Avancerede funktioner</AccordionTrigger>
            <AccordionContent>
              <Card>
                <CardHeader>
                  <CardTitle>Materialebibliotek</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>
                    Vores beregner indeholder et omfattende bibliotek af materialer med foruddefinerede egenskaber. Du kan også tilføje brugerdefinerede materialer ved at angive elasticitetsmodul og tilladelig spænding.
                  </p>
                </CardContent>
              </Card>
              
              <Card className="mt-4">
                <CardHeader>
                  <CardTitle>Flere belastningsscenarier</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>
                    Du kan tilføje flere belastninger på samme bjælke for at simulere mere komplekse scenarier. Brug "Tilføj belastning" knappen for at inkludere flere punktlaster eller fordelte laster.
                  </p>
                </CardContent>
              </Card>

              <Card className="mt-4">
                <CardHeader>
                  <CardTitle>Eksport af resultater</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>
                    Efter beregningen kan du eksportere resultaterne som en PDF-rapport eller CSV-fil for videre analyse eller dokumentation. Find eksportmuligheder i resultatvisningen.
                  </p>
                </CardContent>
              </Card>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <div className="mt-12">
          <h2 className="text-2xl font-semibold mb-4">Har du brug for mere hjælp?</h2>
          <p className="text-muted-foreground">
            Hvis du har spørgsmål, der ikke er besvaret i denne dokumentation, er du velkommen til at kontakte vores support team på <a href="aar@nord-ing.dk" className="text-primary hover:underline">aar@nord-ing.dk</a>.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DocsPage