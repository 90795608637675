import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import type { RootState } from "$types/state";
import type { Node } from "$types/entities";

const nodesAdapter = createEntityAdapter<Node>();
const initialState = nodesAdapter.getInitialState();

const nodesSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    addNode: nodesAdapter.addOne,
    updateNode: nodesAdapter.updateOne,
    removeNode: nodesAdapter.removeOne,
  },
});

export const { addNode, updateNode, removeNode } = nodesSlice.actions;
export const nodesSelector = nodesAdapter.getSelectors<RootState>(
  (state: RootState) => state.nodes
);

export const nodesReducer = nodesSlice.reducer;