import { Grid, GridItem } from '@/components/layout/grid';
import { Separator } from '@/components/ui/separator';
import { ScrollArea } from '@/components/ui/scroll-area';
import BeamPropertyInputs from './BeamPropertyInputs';
import DataVisualsPanel from './DataVisPanel';
import SupportInputs from './SupportInput';
import LoadInputs from './LoadInput';
import CalculateButton from './CalculateButton';

const DataInputPanel = () => (
  <div className="space-y-6 px-6">
    {/* Properties Section */}
    <div>
      <h3 className="text-lg font-semibold mb-4">Dimensioner og Materiale egenskaber</h3>
      <BeamPropertyInputs />
    </div>
    
    
    <Separator />

    <div>
      <h3 className="text-lg font-semibold mb-4">Understøtninger</h3>
      <div className="bg-muted/50 rounded-lg p-4">
        <SupportInputs />
      </div>
    </div>

    <Separator />

    {/* Load Configuration */}
    <div>
      <h3 className="text-lg font-semibold mb-4">Laster</h3>
      <div className="space-y-4">
        <LoadInputs />
      </div>
    </div>

    {/* Calculate Button */}
    <div className="sticky bottom-0 pt-4 bg-background">
      <CalculateButton />
    </div>
  </div>
);

export const BeamCalculator = () => {
  return (
    <div className="h-[calc(100vh-64px)]">
      <Grid 
        split
        splitRatio={[40, 60]} 
        className="h-full"
      >
        {/* Left Panel - Input Section */}
        <GridItem className="border-r h-full overflow-hidden">
          <ScrollArea className="h-full w-full">
            <div className="py-6">
              <DataInputPanel />
            </div>
          </ScrollArea>
        </GridItem>

        {/* Right Panel - Visualization Section */}
        <GridItem className="h-full overflow-hidden">
          <ScrollArea className="h-full w-full">
            <div className="py-6 px-6">
              <DataVisualsPanel />
            </div>
          </ScrollArea>
        </GridItem>
      </Grid>
    </div>
  );
};

export default BeamCalculator;