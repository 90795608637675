// components/layout/grid/Grid.tsx
import React, { ReactNode } from 'react';
import { cn } from '@/lib/utils';

// Grid Props Interface
export interface GridProps {
  children: ReactNode;
  className?: string;
  cols?: {
    default: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  gap?: number;
  gapX?: number;
  gapY?: number;
  align?: 'start' | 'center' | 'end' | 'stretch';
  justify?: 'start' | 'center' | 'end' | 'between' | 'around';
  // New layout control props
  fullHeight?: boolean;
  fullWidth?: boolean;
  autoFlow?: 'row' | 'col' | 'dense';
  autoColumns?: 'auto' | 'min' | 'max' | 'fr';
  autoRows?: 'auto' | 'min' | 'max' | 'fr';
  // New split view props
  split?: boolean;
  splitRatio?: [number, number]; // [left panel %, right panel %]
}

// GridItem Props Interface
export interface GridItemProps {
  children: ReactNode;
  className?: string;
  span?: {
    default?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  start?: {
    default?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  order?: number;
  // New layout props
  grow?: boolean;
  shrink?: boolean;
  overflowY?: 'auto' | 'scroll' | 'hidden';
}

// Grid Component
export const Grid = React.forwardRef<HTMLDivElement, GridProps>(({ 
  children, 
  className,
  cols = { default: 1 },
  gap,
  gapX,
  gapY,
  align = 'stretch',
  justify = 'start',
  fullHeight = false,
  fullWidth = false,
  autoFlow,
  autoColumns,
  autoRows,
  split = false,
  splitRatio = [50, 50],
}, ref) => {

  // Grid template columns based on split or cols prop
  const gridCols = split 
    ? { gridTemplateColumns: `${splitRatio[0]}% ${splitRatio[1]}%` }
    : {
        default: `grid-cols-${cols.default}`,
        sm: cols.sm && `sm:grid-cols-${cols.sm}`,
        md: cols.md && `md:grid-cols-${cols.md}`,
        lg: cols.lg && `lg:grid-cols-${cols.lg}`,
        xl: cols.xl && `xl:grid-cols-${cols.xl}`,
      };

  // Grid flow classes
  const flowClasses = {
    row: 'grid-flow-row',
    col: 'grid-flow-col',
    dense: 'grid-flow-dense',
  };

  // Auto columns/rows classes
  const autoColumnsClasses = {
    auto: 'auto-cols-auto',
    min: 'auto-cols-min',
    max: 'auto-cols-max',
    fr: 'auto-cols-fr',
  };

  const autoRowsClasses = {
    auto: 'auto-rows-auto',
    min: 'auto-rows-min',
    max: 'auto-rows-max',
    fr: 'auto-rows-fr',
  };

  return (
    <div
      ref={ref}
      className={cn(
        'grid',
        // Grid columns
        !split && [
          gridCols.default,
          gridCols.sm,
          gridCols.md,
          gridCols.lg,
          gridCols.xl,
        ],
        // Spacing
        gap && `gap-${gap}`,
        gapX && `gap-x-${gapX}`,
        gapY && `gap-y-${gapY}`,
        // Alignment
        `items-${align}`,
        `justify-${justify}`,
        // Size
        fullHeight && 'h-screen',
        fullWidth && 'w-full',
        // Auto flow
        autoFlow && flowClasses[autoFlow],
        // Auto columns/rows
        autoColumns && autoColumnsClasses[autoColumns],
        autoRows && autoRowsClasses[autoRows],
        className
      )}
      style={split ? { gridTemplateColumns: `${splitRatio[0]}% ${splitRatio[1]}%` } : undefined}
    >
      {children}
    </div>
  );
});

Grid.displayName = 'Grid';

// GridItem Component
export const GridItem = React.forwardRef<HTMLDivElement, GridItemProps>(({
  children,
  className,
  span,
  start,
  order,
  grow = false,
  shrink = false,
  overflowY,
}, ref) => {
  // Span classes based on breakpoints
  const spanClasses = span ? {
    default: span.default && `col-span-${span.default}`,
    sm: span.sm && `sm:col-span-${span.sm}`,
    md: span.md && `md:col-span-${span.md}`,
    lg: span.lg && `lg:col-span-${span.lg}`,
    xl: span.xl && `xl:col-span-${span.xl}`,
  } : {};

  // Start classes based on breakpoints
  const startClasses = start ? {
    default: start.default && `col-start-${start.default}`,
    sm: start.sm && `sm:col-start-${start.sm}`,
    md: start.md && `md:col-start-${start.md}`,
    lg: start.lg && `lg:col-start-${start.lg}`,
    xl: start.xl && `xl:col-start-${start.xl}`,
  } : {};

  return (
    <div
      ref={ref}
      className={cn(
        // Span classes
        spanClasses.default,
        spanClasses.sm,
        spanClasses.md,
        spanClasses.lg,
        spanClasses.xl,
        // Start classes
        startClasses.default,
        startClasses.sm,
        startClasses.md,
        startClasses.lg,
        startClasses.xl,
        // Order
        order && `order-${order}`,
        // Growth
        grow && 'grow',
        shrink && 'shrink',
        // Overflow
        overflowY && `overflow-y-${overflowY}`,
        className
      )}
    >
      {children}
    </div>
  );
});

GridItem.displayName = 'GridItem';

export default Grid;