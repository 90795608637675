import { SectionProperties } from '@/types/sections';
import { BeamStatus } from '@/types/state';
import { v4 as uuidv4 } from 'uuid';

export const DEFAULT_SECTION_PROPERTIES: SectionProperties = {
  E: 210000, // N/mm² (Steel)
  A: 4800,   // mm² (typical IPE200)
  I: 194300  // mm⁴ (typical IPE200)
};

export const createInitialBeam = () => ({
  id: uuidv4(),
  length: 3000,          // 3m in mm
  sectionProperties: DEFAULT_SECTION_PROPERTIES,
  supports: [],
  pointLoads: [],
  lineLoads: [],
  charniers: [],
  status: BeamStatus.DRAFT,
  modelState: 'base' as const
});

