import React from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { SupportType } from '@/types';
import { toast } from '@/hooks/use-toast';
import { selectBeamById } from '@/store/features/beams/beamsSlice';
import { selectActiveBeamId } from '@/store/features/ui/uiSlice';
import { addSupport, removeSupport } from '@/store/features/beams/beamsSlice';

const SupportTypeIcon = ({ type }: { type: SupportType }) => {
  // SVG icons for each support type
  const icons = {
    [SupportType.HINGED]: (
      <svg viewBox="0 0 24 24" className="w-6 h-6">
        <path d="M12 4L4 16h16L12 4z" fill="none" stroke="currentColor" strokeWidth="2"/>
      </svg>
    ),
    [SupportType.ROLLER]: (
      <svg viewBox="0 0 24 24" className="w-6 h-6">
        <path d="M12 4L4 16h16L12 4z" fill="none" stroke="currentColor" strokeWidth="2"/>
        <circle cx="8" cy="18" r="1" fill="currentColor"/>
        <circle cx="16" cy="18" r="1" fill="currentColor"/>
      </svg>
    ),
    [SupportType.FIXED]: (
      <svg viewBox="0 0 24 24" className="w-6 h-6">
        <line x1="4" y1="4" x2="4" y2="20" stroke="currentColor" strokeWidth="2"/>
        <line x1="8" y1="4" x2="8" y2="20" stroke="currentColor" strokeWidth="2"/>
        <line x1="12" y1="4" x2="12" y2="20" stroke="currentColor" strokeWidth="2"/>
      </svg>
    )
  };

  return icons[type];
};

const SupportInputs = () => {
  const dispatch = useAppDispatch();
  const activeBeamId = useAppSelector(selectActiveBeamId);
  const beam = useAppSelector(state => 
    activeBeamId ? selectBeamById(state, activeBeamId) : null
  );

  const [selectedType, setSelectedType] = React.useState<SupportType>(SupportType.HINGED);
  const [location, setLocation] = React.useState<string>('');

  if (!beam) {
    return null;
  }

  const handleAddSupport = () => {
    const locationValue = parseFloat(location);
    
    if (isNaN(locationValue)) {
      toast({
        title: "Invalid Input",
        description: "Please enter a valid number for location",
        variant: "destructive"
      });
      return;
    }

    if (locationValue <= -1 || locationValue > beam.length) {
      toast({
        title: "Invalid Location",
        description: `Location must be between 0 and ${beam.length}mm`,
        variant: "destructive"
      });
      return;
    }

    // Check if a support already exists at this location
    if (beam.supports.some(s => Math.abs(s.location - locationValue) < 1)) {
      toast({
        title: "Invalid Location",
        description: "A support already exists at this location",
        variant: "destructive"
      });
      return;
    }

    dispatch(addSupport({
      beamId: beam.id,
      support: {
        type: selectedType,
        location: locationValue
      }
    }));

    setLocation('');
    
    toast({
      title: "Support Added",
      description: `${selectedType} support added at ${locationValue}mm`
    });
  };

  return (
    <Card>
      <CardContent className="pt-4 space-y-4">
        <div className="grid grid-cols-3 gap-2">
          {Object.values(SupportType).map(type => (
            <Button
              key={type}
              variant={selectedType === type ? "default" : "outline"}
              className="flex flex-col items-center gap-2 p-8"
              onClick={() => setSelectedType(type)}
            >
               <span className="text-sm"> {type.toLocaleUpperCase()}</span>
            </Button>
          ))}
        </div>

        <div className="flex items-end gap-2">
          <div className="flex-1">
            <Label htmlFor="location">Lokation (mm)</Label>
            <Input
              id="location"
              type="number"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="0"
              min={0}
              max={beam.length}
              step={10}
            />
          </div>
          <Button onClick={handleAddSupport}>
            Tilføj Understøtninger
          </Button>
        </div>

        {/* Existing Supports List */}
        {beam.supports.length > 0 && (
          <div className="space-y-2">
            <Label>Understøtninger</Label>
            <div className="space-y-1">
              {beam.supports.map((support, index) => (
                <div key={index} className="flex items-center justify-between bg-muted p-2 rounded-md">
                  <div className="flex items-center gap-2">
                    <SupportTypeIcon type={support.type} />
                    <span>{support.location}mm</span>
                  </div>
                  <Button 
                    variant="destructive" 
                    size="sm"
                    onClick={() => dispatch(removeSupport({
                      beamId: beam.id,
                      supportIndex: index
                    }))}
                  >
                    Fjern
                  </Button>
                </div>
              ))}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default SupportInputs;