import React from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { updateSectionProperties, updateBeamLength, selectBeamProperties } from '@/store/features/beams/beamsSlice';
import { selectActiveBeamId } from '@/store/features/ui/uiSlice';
import { toast } from '@/hooks/use-toast';
import { Alert, AlertDescription } from '@/components/ui/alert';

const BeamPropertyInputs = () => {
  const dispatch = useAppDispatch();
  const activeBeamId = useAppSelector(selectActiveBeamId);
  const properties = useAppSelector(state => 
    activeBeamId ? selectBeamProperties(state, activeBeamId) : null
  );

  if (!activeBeamId || !properties) {
    return (
      <Card>
        <CardContent className="pt-4">
          <Alert>
            <AlertDescription>
              No beam selected. Please select a beam to edit properties.
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    );
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: 'length' | 'E' | 'A' | 'I'
  ) => {
    const value = parseFloat(e.target.value);
    
    if (isNaN(value) || value <= 0) {
      toast({
        title: "Invalid Input",
        description: "Please enter a positive number",
        variant: "destructive",
      });
      return;
    }

    if (field === 'length') {
      dispatch(updateBeamLength({ 
        beamId: activeBeamId, 
        length: value 
      }));
    } else {
      dispatch(updateSectionProperties({
        beamId: activeBeamId,
        properties: { [field]: value }
      }));
    }
  };

  const inputFields = [
    { id: 'length', label: 'Længde', unit: 'mm', value: properties.length },
    { id: 'E', label: "Young's Modulus (E)", unit: 'N/mm²', value: properties.E },
    { id: 'I', label: 'Moment of Inertia (I)', unit: 'mm⁴', value: properties.I },
    { id: 'A', label: 'Cross-sectional Area (A)', unit: 'mm²', value: properties.A },
  ] as const;

  return (
    <Card>
      <CardContent className="space-y-6 pt-4">
        <div className="grid grid-cols-2 gap-4">
          {inputFields.map(({ id, label, unit, value }) => (
            <div key={id} className="space-y-2">
              <Label htmlFor={id}>{label}</Label>
              <div className="flex items-center space-x-2">
                <Input
                  id={id}
                  type="number"
                  value={value}
                  onChange={(e) => handleInputChange(e, id as 'length' | 'E' | 'A' | 'I')}
                  min={0}
                  step={id === 'E' ? 1000 : id === 'I' ? 100 : 10}
                  className="font-mono"
                />
                <span className="text-sm text-muted-foreground min-w-[50px]">
                  {unit}
                </span>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default BeamPropertyInputs;