import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import type { RootState } from "$types/state";
import type { Element } from "$types/entities";


const elementsAdapter = createEntityAdapter<Element>();
const initialState = elementsAdapter.getInitialState();

const elementsSlice = createSlice({
  name: "elements",
  initialState,
  reducers: {
    addElement: elementsAdapter.addOne,
    updateElement: elementsAdapter.updateOne,
    removeElement: elementsAdapter.removeOne,
  },
});

export const { addElement, updateElement, removeElement } = elementsSlice.actions;
export const elementsSelector = elementsAdapter.getSelectors<RootState>(
  (state: RootState) => state.elements
);

export const elementsReducer = elementsSlice.reducer;