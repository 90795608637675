import { PointLoad, LineLoad } from '@/types/loads';

interface LoadVisualizationProps {
  pointLoads: PointLoad[];
  lineLoads: LineLoad[];
  scaleFactor: number;
  centerY: number;
  margin: { left: number; right: number; top: number; bottom: number; };
  loadState: 'ULS' | 'SLS';
}

const LoadVisualization = ({
  pointLoads,
  lineLoads,
  scaleFactor,
  centerY,
  margin,
  loadState
}: LoadVisualizationProps) => {
  const ARROW_HEIGHT = 60;
  const ARROW_HEAD_SIZE = 8;
  const LOAD_SPACING = 40;

  const getLoadValue = (load: PointLoad | LineLoad) => {
    if ('Q_ULS' in load) {
      return loadState === 'ULS' ? load.Q_ULS : load.Q_SLS;
    }
    return loadState === 'ULS' ? load.q_ULS : load.q_SLS;
  };

  return (
    <g className="loads">
      {/* Arrow marker definitions - one for each direction */}
      <defs>
        <marker
          id="load-arrow-down"
          viewBox="0 0 10 10"
          refX="5"
          refY="0"
          markerWidth={ARROW_HEAD_SIZE}
          markerHeight={ARROW_HEAD_SIZE}
        >
          <path d="M 0 0 L 5 5 L 10 0" fill="currentColor" />
        </marker>
      </defs>

      {/* Point Loads */}
      {pointLoads.map((load) => {
        const x = margin.left + load.location * scaleFactor;
        const value = getLoadValue(load);

        return (
          <g 
            key={`point-load-${load.id}`} 
            transform={`translate(${x},${centerY-2})`}
          >
            <line
              x1="0"
              y1={-ARROW_HEIGHT}
              x2="0"
              y2={-ARROW_HEAD_SIZE}
              stroke="currentColor"
              strokeWidth={2}
              markerEnd="url(#load-arrow-down)"
            />
            <text
              x="5"
              y={-ARROW_HEIGHT/2}
              fontSize="12"
              textAnchor="start"
              dominantBaseline="middle"
              fill="currentColor"
            >
              {`${Math.abs(value)} kN`}
            </text>
          </g>
        );
      })}

      {/* Line Loads */}
      {lineLoads.map((load) => {
        const startX = margin.left + load.start_location * scaleFactor;
        const endX = margin.left + load.end_location * scaleFactor;
        const width = endX - startX;
        const numArrows = Math.max(2, Math.floor(width / LOAD_SPACING));
        const spacing = width / (numArrows - 1);
        const value = getLoadValue(load);

        return (
          <g 
            key={`line-load-${load.id}`} 
            transform={`translate(0,${centerY-2})`}
          >
            <line
              x1={startX}
              y1={-ARROW_HEIGHT}
              x2={endX}
              y2={-ARROW_HEIGHT}
              stroke="currentColor"
              strokeWidth={2}
            />
            
            {Array.from({ length: numArrows }).map((_, i) => (
              <line
                key={`arrow-${i}`}
                x1={startX + i * spacing}
                y1={-ARROW_HEIGHT}
                x2={startX + i * spacing}
                y2={-ARROW_HEAD_SIZE}
                stroke="currentColor"
                strokeWidth={2}
                markerEnd="url(#load-arrow-down)"
              />
            ))}

            <text
              x={(startX + endX) / 2}
              y={-ARROW_HEIGHT - 10}
              fontSize="12"
              textAnchor="middle"
              fill="currentColor"
            >
              {`${Math.abs(value)} kN/m`}
            </text>
          </g>
        );
      })}
    </g>
  );
};

export default LoadVisualization;