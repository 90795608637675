import { EntityId } from "./common";
import { EntityState } from "@reduxjs/toolkit";
import { Beam, Node, Element } from "./entities";
import { Material } from "./materials";
import { Release } from "./releases";
import { CrossSection } from "./sections";
import { AnalysisState } from "@/store/features/analysis/analysisSlice";
export enum BeamStatus {
    DRAFT = 'draft',
    COMPLETE = 'complete',
    CALCULATING = 'calculating',
    ERROR = 'error',
}

export interface ValidationError{
    entityId: EntityId;
    entityType: 'beam' | 'node' | 'element';
    errorCode: string; 
    message: string;
}

// Root state interface for TypeScript
export interface RootState {
    beams: EntityState<Beam, EntityId>;
    nodes: EntityState<Node, EntityId>;
    elements: EntityState<Element, EntityId>;
    materials: EntityState<Material, EntityId>;
    releases: EntityState<Release, EntityId>;
    analysis: AnalysisState;
    ui: {
      activeBeamId: EntityId | null;
      selectedElementId: EntityId | null;
      errors: ValidationError[];
      calculationStatus: 'idle' | 'loading' | 'success' | 'error';
    };
  }

  // Calculation state
export interface CalculationState {
    beams: EntityState<Beam, EntityId>;
    nodes: EntityState<Node, EntityId>;
    elements: EntityState<Element, EntityId>;
    materials: EntityState<Material, EntityId>;
    releases: EntityState<Release, EntityId>;
    ui: {
      activeBeamId: EntityId | null;
      selectedElementId: EntityId | null;
      modelState: 'base' | 'calculation';
      calculationStatus: 'idle' | 'loading' | 'success' | 'error';
    };
  }

  export interface AddPointLoadPayload {
    beamId: EntityId;
    position: number;  // mm from start
    magnitude: number; // kN
  }
  
  export interface AddDistributedLoadPayload {
    beamId: EntityId;
    elementId: EntityId;
    magnitude: number; // kN/m
  }
  
  export interface CreateBeamPayload {
    length: number;    // mm
    crossSection: CrossSection;
    defaultMaterial: Material;
  }
  
  export interface SplitElementPayload {
    elementId: EntityId;
    position: number;  // mm from element start
  }
  
  // Selector return types
  export interface BeamWithDetails {
    id: EntityId;
    length: number;
    status: BeamStatus;
    elements: Array<{
      id: EntityId;
      startNode: Node;
      endNode: Node;
      distributedLoad?: number;
      crossSection: CrossSection;
    }>;
  }