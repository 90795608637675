// store/index.ts
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './features';
import { createInitialBeam } from './shared/constants';
import { beamsAdapter } from './features/beams/beamsSlice';

// Create initial beam
const initialBeam = createInitialBeam();

// Create initial state with the beam
const preloadedState = {
  beams: beamsAdapter.getInitialState(),
  ui: {
    activeBeamId: initialBeam.id,
    selectedElementId: null,
    errors: [],
    calculationStatus: 'idle' as const
  }
};

// Add initial beam to state
preloadedState.beams = beamsAdapter.addOne(preloadedState.beams, initialBeam);

export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

// Export types
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<typeof store.getState>;