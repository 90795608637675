import {createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '$types/state';
import { Material } from '$types/materials';

const materialsAdapter = createEntityAdapter<Material>();
const initialState = materialsAdapter.getInitialState();

export const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    addMaterial: materialsAdapter.addOne,
    updateMaterial: materialsAdapter.updateOne,
    removeMaterial: materialsAdapter.removeOne,
  },
});

export const { addMaterial, updateMaterial, removeMaterial } = materialsSlice.actions;
export const materialsSelector = materialsAdapter.getSelectors<RootState>(
  (state: RootState) => state.materials
);

export const materialsReducer = materialsSlice.reducer;
