import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {  Calculator, Clock } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';

// Only the active tool
const activeTool = {
  id: 'beam-calculator',
  title: 'Bjælke Beregner',
  description: 'Avanceret beregningsværktøj til at analysere og visualisere bjælkestyrke med præcision og enkelhed.',
  icon: Calculator,
  features: [
    'Præcis beregning af bjælker',
    'Dynamisk visualisering',
    'Detaljerede rapporter'
  ],
  version: 'v0.1.0',
  path: '/calculator'
};

const futurePlaceholders = [
  {
    id: 'tool-1',
    title: 'Dørhuls beregner',
    comingSoon: 'Kommer 2025'
  },
  {
    id: 'tool-2',
    title: 'Brænd',
    comingSoon: 'Kommer 2025'
  },
  {
    id: 'tool-3',
    title: 'Vind',
    comingSoon: 'Kommer'
  },

];

const PlaceholderCard = ({ title, comingSoon }: { title: string, comingSoon: string }) => (
  <Card className="relative group hover:shadow-md transition-all duration-300">
    <div className="p-6 space-y-6">
      {/* Icon Header */}
      <div className="h-12 w-12 rounded-lg bg-muted flex items-center justify-center text-muted-foreground">
        <Clock size={24} />
      </div>

      {/* Content */}
      <div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <div className="space-y-3">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-5/6" />
        </div>
      </div>

      {/* Features */}
      <div className="space-y-2">
        <Skeleton className="h-3 w-2/3" />
        <Skeleton className="h-3 w-3/4" />
        <Skeleton className="h-3 w-1/2" />
      </div>

      {/* Coming Soon Badge */}
      <div className="absolute top-4 right-4">
        <span className="px-2 py-1 text-xs font-medium bg-primary/10 text-primary rounded-full">
          {comingSoon}
        </span>
      </div>
    </div>
  </Card>
);

const HomePage = () => {
  return (
    <div className="min-h-screen bg-background">
      {/* Hero Section */}
      <section className="relative overflow-hidden py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-12 items-center">
            <div className="space-y-8">
              <div>
                <h1 className="text-4xl font-extrabold tracking-tighter sm:text-2xl xl:text-6xl/none bg-clip-text   pt-4 pb-4 justify-self-center">
                  Structural Tools
                </h1>
                <h2 className="w-full  text-nowrap text-xl text-muted-foreground max-w-2xl justify-self-center">
              Specialudviklede værktøjer der kombinerer præcision med brugervenligt design.
            </h2>
               
              </div>
            
            </div>
            <div className="relative">
             
            </div>
          </div>
        </div>
      </section>

      {/* Tools Section */}
      <section className="py-20 bg-muted/50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
           
          
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Active Tool */}
            <Card className="relative group hover:shadow-lg transition-all duration-300">
              <div className="p-6 space-y-6">
                {/* Icon Header */}
                <div className="h-12 w-12 rounded-lg bg-primary/10 flex items-center justify-center text-primary">
                  <activeTool.icon size={24} />
                </div>

                {/* Content */}
                <div>
                  <h3 className="text-xl font-semibold mb-2">{activeTool.title}</h3>
                  <p className="text-muted-foreground mb-4">
                    {activeTool.description}
                  </p>
                </div>

                {/* Features */}
                <ul className="space-y-2">
                  {activeTool.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-sm text-muted-foreground">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary/60 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>

                {/* Action Footer */}
                <div className="flex items-center justify-between pt-4">
                  <Button variant="default" asChild>
                    <a href={activeTool.path}>
                      Start app
                    </a>
                  </Button>
                  <span className="text-sm text-muted-foreground">
                    {activeTool.version}
                  </span>
                </div>
              </div>
            </Card>

            {/* Placeholder Future Tools */}
            {futurePlaceholders.map(tool => (
              <PlaceholderCard 
                key={tool.id} 
                title={tool.title} 
                comingSoon={tool.comingSoon}
              />
            ))}
          </div>
        </div>
      </section>

   
    </div>
  );
};

export default HomePage;