/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '$types/state';

// Type Definitions
export interface VisualizationState {
  activeTab: 'structure' | 'displacement' | 'bending_moment' | 'shear_force';
  svgs: {
    structure?: string;
    displacement?: string;
    bending_moment?: string;
    shear_force?: string;
  };
}

export interface AnalysisState {
  input: any | null;
  results: any | null;
  visualizations: VisualizationState;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  lastCalculated: string | null;
}

// API Configuration
const getApiConfig = () => {
  // Always get the URL from environment variables
  const apiUrl = import.meta.env.VITE_API_URL;
  const apiKey = import.meta.env.VITE_API_KEY;

  console.log('API Config:', {
    mode: import.meta.env.MODE,
    apiUrl,
    hasApiKey: !!apiKey
  });

  if (!apiUrl) {
    console.error('API URL is not configured!');
  }

  return {
    apiUrl,
    apiKey
  };
};

// Initial State
const initialState: AnalysisState = {
  input: null,
  results: null,
  visualizations: {
    activeTab: 'structure',
    svgs: {}
  },
  status: 'idle',
  error: null,
  lastCalculated: null
};

// API Helper Function
const makeRequest = async (endpoint: string, options: RequestInit) => {
  const { apiUrl, apiKey } = getApiConfig();
  
  // Remove trailing slash from API URL and leading slash from endpoint
  const cleanApiUrl = apiUrl?.replace(/\/$/, '');
  const cleanEndpoint = endpoint.replace(/^\//, '');
  
  // Combine URLs properly
  const fullUrl = endpoint.startsWith('http') 
    ? endpoint 
    : `${cleanApiUrl}/${cleanEndpoint}`;
  
  try {
    console.log('Making request to:', fullUrl, {
      method: options.method,
      headers: {
        ...options.headers,
        'x-api-key': 'PRESENT',  // Don't log the actual key
      }
    });

    const response = await fetch(fullUrl, { 
      ...options, 
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey || '',
        ...options.headers,
      }
    });
    
    // Log the response status and headers
    console.log('Response received:', {
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries(response.headers.entries())
    });

    // Try to get the response content
    const textResponse = await response.text();
    console.log('Raw response:', textResponse);

    let data;
    try {
      data = JSON.parse(textResponse);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (parseError) {
      console.error('Failed to parse response as JSON:', textResponse);
      throw new Error('Server returned invalid JSON response');
    }
    
    if (!response.ok) {
      throw new Error(data.error?.message || `API request failed with status ${response.status}`);
    }

    return data;
  } catch (error: any) {
    console.error('Request failed:', {
      url: fullUrl,
      error: error.message,
      originalError: error
    });
    throw error;
  }
};

// Async Thunk
export const calculateBeam = createAsyncThunk(
  'analysis/calculate',
  async (input: any, { rejectWithValue }) => {
    try {
      console.log('Calculation started with input:', input);

      const result = await makeRequest('https://server-ing.onrender.com/api/calculations', {
        method: 'POST',
        body: JSON.stringify(input),
      });

      console.log('Calculation completed:', result);
      return result;
    } catch (error: any) {
      console.error('Calculation failed:', error);
      return rejectWithValue(error.message || 'Failed to process calculation');
    }
  }
);

// Rest of the slice remains the same...
export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setActiveVisualization: (state, action) => {
      state.visualizations.activeTab = action.payload;
    },
    clearResults: (state) => {
      state.results = null;
      state.visualizations.svgs = {};
      state.status = 'idle';
      state.error = null;
    },
    setInput: (state, action) => {
      state.input = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculateBeam.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(calculateBeam.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.results = action.payload;
        state.lastCalculated = new Date().toISOString();
        
        const visualizations = action.payload.result?.data?.visualizations?.svgs;
        if (visualizations) {
          state.visualizations.svgs = visualizations;
        }
      })
      .addCase(calculateBeam.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string || 'An unexpected error occurred';
        state.results = null;
        state.visualizations.svgs = {};
      });
  }
});

// Actions
export const { 
  setActiveVisualization, 
  clearResults,
  setInput 
} = analysisSlice.actions;

// Selectors
export const selectAnalysisStatus = (state: RootState) => state.analysis.status;
export const selectAnalysisResults = (state: RootState) => state.analysis.results;
export const selectVisualizations = (state: RootState) => state.analysis.visualizations;
export const selectActiveVisualization = (state: RootState) => state.analysis.visualizations.activeTab;
export const selectCalculations = (state: RootState) => 
  state.analysis.results?.result?.data?.calculations || null;
export const selectSvgs = (state: RootState) => state.analysis.results?.result?.data?.visualizations?.svgs;


export default analysisSlice.reducer;