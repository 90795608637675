// routes/index.tsx
import { Route, createRootRoute, createRouter } from '@tanstack/react-router';
import HomePage from './home';
import { BeamCalculator } from './calculator/index';
import { store } from '@/store';
import { Root } from './__root';
import DocsPage from './docs';

// Create root route
export const rootRoute = createRootRoute({
  component: () => <div className="min-h-screen"><Root/></div>,
});

// Create routes
const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: HomePage,
});

// Direct calculator route (no beam ID needed yet)
const calculatorRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/calculator',
  component: BeamCalculator,
 
});

const docsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/docs',
  component: () => <DocsPage/>
});

// Create route tree
const routeTree = rootRoute.addChildren([
  indexRoute,
  calculatorRoute,
  docsRoute
]);

// Create router instance
export const router = createRouter({
  routeTree,
  context: {
    store: store,
  },
});

export default router;