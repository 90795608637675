import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "$types/state";
import type { Release } from "$types/releases";

const releasesAdapter = createEntityAdapter<Release, string>(
    {
        selectId: (release) => release.elementid
    }
);

const initialState = releasesAdapter.getInitialState();

const releasesSlice = createSlice({
    name: "releases",
    initialState,
    reducers: {
        addRelease: releasesAdapter.addOne,
        updateRelease: releasesAdapter.updateOne,
        removeRelease: releasesAdapter.removeOne,
    },
});

export const { addRelease, updateRelease, removeRelease } = releasesSlice.actions;
export const releasesSelector = releasesAdapter.getSelectors<RootState>(
    (state: RootState) => state.releases
);
export const releasesReducer = releasesSlice.reducer;