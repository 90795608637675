import { Button } from '@/components/ui/button';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectActiveBeamId } from '@/store/features/ui/uiSlice';
import { calculateBeam } from '@/store/features/analysis/analysisSlice';
import { selectBeamById } from '@/store/features/beams/beamsSlice';
import { toast } from '@/hooks/use-toast';
import { CalculationInput } from '@/types/analysis';
import LoadingSpinner from '@/components/common/LoadingSpinner';


export const CalculateButton = () => {
  const dispatch = useAppDispatch();
  const activeBeamId = useAppSelector(selectActiveBeamId);
  const beam = useAppSelector(state => 
    activeBeamId ? selectBeamById(state, activeBeamId) : null
  );
  const calculationStatus = useAppSelector(state => state.analysis.status);
  const isCalculating = calculationStatus === 'loading';
  
  const validateBeam = () => {
    if (!beam) return false;
    
    // Must have at least 2 supports
    if (beam.supports.length < 2) {
      toast({
        title: "Invalid Configuration",
        description: "Beam must have at least 2 supports",
        variant: "destructive"
      });
      return false;
    }

    // Must have valid section properties
    if (!beam.sectionProperties.E || !beam.sectionProperties.A || !beam.sectionProperties.I) {
      toast({
        title: "Invalid Properties",
        description: "Missing section properties",
        variant: "destructive"
      });
      return false;
    }

    return true;
  };

  const prepareCalculationInput = (): CalculationInput => {
    if (!beam) throw new Error("No active beam");

    return {
      geometry: [{
        length: beam.length.toString()
      }],
      supports: beam.supports.map(s => ({
        type: s.type as "hinged" | "roller" | "fixed",
        location: s.location.toString()
      })),
      point_loads: beam.pointLoads.map(p => ({
        location: p.location.toString(),
        Q_ULS: p.Q_ULS.toString(),
        Q_SLS: p.Q_SLS.toString()
      })),
      line_loads: beam.lineLoads.map(l => ({
        start_location: l.start_location.toString(),
        end_location: l.end_location.toString(),
        q_ULS: l.q_ULS.toString(),
        q_SLS: l.q_SLS.toString()
      })),
      section_data: [{
        E: beam.sectionProperties.E.toString(),
        A: beam.sectionProperties.A.toString(),
        I: beam.sectionProperties.I.toString()
      }],
      //scharnier: [] // Not implemented yet
    };
  };

  const handleCalculate = async () => {
    try {
      if (!validateBeam()) return;

      const input = prepareCalculationInput();
      await dispatch(calculateBeam(input)).unwrap();
      
      toast({
        title: "Calculation Complete",
        description: "Results are ready to view",
      });
    } catch (error) {
      toast({
        title: "Calculation Failed",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        variant: "destructive"
      });
    }
  };

  return (
  <Button
    onClick={handleCalculate}
    disabled={!beam || isCalculating}
    className="w-full mt-4"
  >
    {isCalculating ? (
      <div className="flex items-center gap-2">
        <LoadingSpinner size="sm" />
        <span>Beregner...</span>
      </div>
    ) : (
      "Beregn bjælken"
    )}
  </Button>
);}

export default CalculateButton;