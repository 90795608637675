export type EntityId = string;

export enum SupportType {
    HINGED = "hinged",
    ROLLER = "roller",
    FIXED = "fixed",
}

export enum CrossSectionType {
    IPE = "IPE",
    HEA = "HEA",
    HEB = "HEB",
}

export interface Support {
    type: SupportType;
    location: number;

}

  
