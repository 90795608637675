import { SupportType } from '@/types';

interface SupportSymbolProps {
  type: SupportType;
  size?: number;
}

const SupportSymbol = ({ type, size = 40 }: SupportSymbolProps) => {
  // Make calculations relative to size
  const triangleSize = size * 0.5;  // Size for triangular supports
  const groundLineWidth = size * 0.8;
  const rollerSize = size * 0.08;  // Size for roller circles
  const fixedLinesCount = 6;  // Number of fixed support lines
  const fixedLineSpacing = size * 0.12;

  const renderFixedSupport = () => (
    <g>
      {/* Multiple vertical parallel lines */}
      {Array.from({ length: fixedLinesCount }).map((_, i) => {
        const x = -((fixedLinesCount - 1) * fixedLineSpacing) / 2 + i * fixedLineSpacing;
        return (
          <line
            key={i}
            x1={x}
            y1={-size * 0.5}
            x2={x}
            y2={size * 0.5}
            stroke="currentColor"
            strokeWidth={2}
          />
        );
      })}
      {/* Ground line */}
      <line
        x1={-groundLineWidth / 2}
        y1={size * 0.5}
        x2={groundLineWidth / 2}
        y2={size * 0.5}
        stroke="currentColor"
        strokeWidth={2}
      />
    </g>
  );

  const renderPinnedSupport = () => (
    <g>
      {/* Triangle outline */}
      <path
        d={`M 0 0 
            L ${triangleSize} ${triangleSize} 
            L ${-triangleSize} ${triangleSize} 
            Z`}
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      />
      {/* Ground line */}
      <line
        x1={-groundLineWidth / 2}
        y1={triangleSize}
        x2={groundLineWidth / 2}
        y2={triangleSize}
        stroke="currentColor"
        strokeWidth={2}
      />
    </g>
  );

  const renderRollerSupport = () => (
    <g>
      {/* Triangle */}
      <path
        d={`M 0 0 
            L ${triangleSize} ${triangleSize} 
            L ${-triangleSize} ${triangleSize} 
            Z`}
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      />
      {/* Rollers - series of small circles */}
      {[-2, -1, 0, 1, 2].map((offset) => (
        <circle
          key={offset}
          cx={offset * rollerSize * 2}
          cy={triangleSize + rollerSize * 2}
          r={rollerSize}
          fill="currentColor"
        />
      ))}
      {/* Ground line */}
      <line
        x1={-groundLineWidth / 2}
        y1={triangleSize + rollerSize * 4}
        x2={groundLineWidth / 2}
        y2={triangleSize + rollerSize * 4}
        stroke="currentColor"
        strokeWidth={2}
      />
    </g>
  );

  return (
    <g transform={`translate(0,0)`}>
      {(() => {
        switch (type) {
          case SupportType.FIXED:
            return renderFixedSupport();
          case SupportType.HINGED:
            return renderPinnedSupport();
          case SupportType.ROLLER:
            return renderRollerSupport();
          default:
            return null;
        }
      })()}
    </g>
  );
};

export default SupportSymbol;