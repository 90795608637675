// store/features/index.ts
import { combineReducers } from '@reduxjs/toolkit';
import {elementsReducer} from './elements/elementsSlice';
import {nodesReducer} from './nodes/nodesSlice';
import {materialsReducer} from './materials/materialsSlice';
import {releasesReducer} from './releases/releasesSlice';
import uiReducer from './ui/uiSlice';
import beamsReducer from './beams/beamsSlice';
import analysisReducer from './analysis/analysisSlice';

export const rootReducer = combineReducers({
  beams: beamsReducer,
  elements: elementsReducer,
  nodes: nodesReducer,
  materials: materialsReducer,
  releases: releasesReducer,
  ui: uiReducer,
  analysis: analysisReducer
});

// Export all feature actions and selectors
export * from './beams';
export * from './elements/index';
export * from './nodes/index';
export * from './materials/index';
export * from './releases/index';
export * from './ui/uiSlice';
// store/features/beams/beamsSlice.ts

