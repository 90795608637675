import React from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { toast } from '@/hooks/use-toast';
import { addPointLoad, addLineLoad, removePointLoad, removeLineLoad } from '@/store/features/beams/beamsSlice';
import { selectActiveBeamId } from '@/store/features/ui/uiSlice';
import { selectBeamById } from '@/store/features/beams/beamsSlice';

const LoadInputs = () => {
  const dispatch = useAppDispatch();
  const activeBeamId = useAppSelector(selectActiveBeamId);
  const beam = useAppSelector(state => 
    activeBeamId ? selectBeamById(state, activeBeamId) : null
  );

  const [pointLoadInputs, setPointLoadInputs] = React.useState({
    location: '',
    Q_ULS: '',
    Q_SLS: ''
  });

  const [lineLoadInputs, setLineLoadInputs] = React.useState({
    start_location: '',
    end_location: '',
    q_ULS: '',
    q_SLS: ''
  });

  if (!beam) return null;

  const handleAddPointLoad = () => {
    const location = parseFloat(pointLoadInputs.location);
    const Q_ULS = parseFloat(pointLoadInputs.Q_ULS);
    const Q_SLS = parseFloat(pointLoadInputs.Q_SLS);

    if (isNaN(location) || location < 0 || location > beam.length) {
      toast({
        title: "Invalid Location",
        description: `Location must be between 0 and ${beam.length}mm`,
        variant: "destructive"
      });
      return;
    }

    if (isNaN(Q_ULS) || isNaN(Q_SLS)) {
      toast({
        title: "Invalid Load",
        description: "Please enter valid load values",
        variant: "destructive"
      });
      return;
    }

    dispatch(addPointLoad({
      beamId: beam.id,
      load: {
        id: crypto.randomUUID(),
        location,
        Q_ULS,
        Q_SLS
      }
    }));

    setPointLoadInputs({ location: '', Q_ULS: '', Q_SLS: '' });
    toast({ title: "Point Load Added" });
  };

  const handleAddLineLoad = () => {
    const start = parseFloat(lineLoadInputs.start_location);
    const end = parseFloat(lineLoadInputs.end_location);
    const q_ULS = parseFloat(lineLoadInputs.q_ULS);
    const q_SLS = parseFloat(lineLoadInputs.q_SLS);

    if (isNaN(start) || isNaN(end) || start < 0 || end > beam.length || start >= end) {
      toast({
        title: "Invalid Location",
        description: "Please enter valid start and end locations",
        variant: "destructive"
      });
      return;
    }

    if (isNaN(q_ULS) || isNaN(q_SLS)) {
      toast({
        title: "Invalid Load",
        description: "Please enter valid load values",
        variant: "destructive"
      });
      return;
    }

    dispatch(addLineLoad({
      beamId: beam.id,
      load: {
        id: crypto.randomUUID(),
        start_location: start,
        end_location: end,
        q_ULS,
        q_SLS
      }
    }));

    setLineLoadInputs({ start_location: '', end_location: '', q_ULS: '', q_SLS: '' });
    toast({ title: "Line Load Added" });
  };

  return (
    <Card>
      <Tabs defaultValue="point" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="point">Punkt last</TabsTrigger>
          <TabsTrigger value="line">Linie last</TabsTrigger>
        </TabsList>

        <TabsContent value="point">
          <CardContent className="space-y-4">
            {/* Point Load Inputs */}
            <div className="space-y-2">
              <Label>Lokation (mm)</Label>
              <Input
                type="number"
                value={pointLoadInputs.location}
                onChange={e => setPointLoadInputs(prev => ({ ...prev, location: e.target.value }))}
                min={0}
                max={beam.length}
                step={10}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label>ULS Last (kN)</Label>
                <Input
                  type="number"
                  value={pointLoadInputs.Q_ULS}
                  onChange={e => setPointLoadInputs(prev => ({ ...prev, Q_ULS: e.target.value }))}
                  step={0.1}
                />
              </div>
              <div className="space-y-2">
                <Label>SLS Last (kN)</Label>
                <Input
                  type="number"
                  value={pointLoadInputs.Q_SLS}
                  onChange={e => setPointLoadInputs(prev => ({ ...prev, Q_SLS: e.target.value }))}
                  step={0.1}
                />
              </div>
            </div>

            <Button 
              className="w-1/2" 
              onClick={handleAddPointLoad}
            >
              Tilføj Last
            </Button>

            {/* Existing Point Loads */}
            {beam.pointLoads.length > 0 && (
              <div className="mt-4 space-y-2">
                <Label>Punkt Laster</Label>
                <div className="space-y-2">
                  {beam.pointLoads.map((load) => (
                    <div key={load.id} className="flex items-center justify-between p-2 bg-muted rounded-md">
                      <div className="grid grid-cols-3 gap-4 flex-1">
                        <span>{load.location}mm</span>
                        <span>{load.Q_ULS}kN ULS</span>
                        <span>{load.Q_SLS}kN SLS</span>
                      </div>
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => dispatch(removePointLoad({ beamId: beam.id, loadId: load.id }))}
                      >
                        Fjern
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </CardContent>
        </TabsContent>

        <TabsContent value="line">
          <CardContent className="space-y-4">
            {/* Line Load Inputs */}
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label>Start Lokation (mm)</Label>
                <Input
                  type="number"
                  value={lineLoadInputs.start_location}
                  onChange={e => setLineLoadInputs(prev => ({ ...prev, start_location: e.target.value }))}
                  min={0}
                  max={beam.length}
                  step={10}
                />
              </div>
              <div className="space-y-2">
                <Label>Slut Lokation (mm)</Label>
                <Input
                  type="number"
                  value={lineLoadInputs.end_location}
                  onChange={e => setLineLoadInputs(prev => ({ ...prev, end_location: e.target.value }))}
                  min={0}
                  max={beam.length}
                  step={10}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label>ULS Last (kN/m)</Label>
                <Input
                  type="number"
                  value={lineLoadInputs.q_ULS}
                  onChange={e => setLineLoadInputs(prev => ({ ...prev, q_ULS: e.target.value }))}
                  step={0.1}
                />
              </div>
              <div className="space-y-2">
                <Label>SLS Last (kN/m)</Label>
                <Input
                  type="number"
                  value={lineLoadInputs.q_SLS}
                  onChange={e => setLineLoadInputs(prev => ({ ...prev, q_SLS: e.target.value }))}
                  step={0.1}
                />
              </div>
            </div>

            <Button 
              className="w-1/2" 
              onClick={handleAddLineLoad}
            >
              Tilføj linielast
            </Button>

            {/* Existing Line Loads */}
            {beam.lineLoads.length > 0 && (
              <div className="mt-4 space-y-2">
                <Label>Linjelaster</Label>
                <div className="space-y-2">
                  {beam.lineLoads.map((load) => (
                    <div key={load.id} className="flex items-center justify-between p-2 bg-muted rounded-md">
                      <div className="grid grid-cols-4 gap-4 flex-1">
                        <span>{load.start_location}mm</span>
                        <span>{load.end_location}mm</span>
                        <span>{load.q_ULS}kN/m ULS</span>
                        <span>{load.q_SLS}kN/m SLS</span>
                      </div>
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => dispatch(removeLineLoad({ beamId: beam.id, loadId: load.id }))}
                      >
                        Fjern
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </CardContent>
        </TabsContent>
      </Tabs>
    </Card>
  );
};

export default LoadInputs;