/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button } from '@/components/ui/button';
import { FileText } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

interface ReactionForce {
  Fx?: number;
  Fy?: number;
  M?: number;
}

interface Displacement {
  ux: number;
  uy: number;
  phi: number;
}

interface ElementForces {
  N: number[];
  V: number[];
  M: number[];
}

interface BeamProperties {
  length: number;
  E: number;
  I: number;
  A: number;
}

interface Calculations {
  reaction_forces: Record<string, ReactionForce>;
  displacements: Record<string, Displacement>;
  element_forces: Record<string, ElementForces>;
}

interface SVGs {
  structure?: string;
  displacement?: string;
  bending_moment?: string;
  shear_force?: string;
}

interface PDFReportProps {
  calculations: Calculations;
  beamProperties: BeamProperties;
  svgs: SVGs;
}

const PDFReport: React.FC<PDFReportProps> = ({ 
  calculations, 
  beamProperties,
  svgs 
}) => {
  const formatValue = (value: number | undefined | null): string => {
    if (value === undefined || value === null) return '-';
    return value.toFixed(2);
  };

  // Helper function to convert SVG to image
  const svgToImage = async (svgString: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      try {
        // Create a Blob from the SVG string
        const blob = new Blob([svgString], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        // Create an image element
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;

        img.onload = () => {
          // Set canvas size to match SVG
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw SVG to canvas
          ctx.drawImage(img, 0, 0);

          // Convert to base64
          const base64 = canvas.toDataURL('image/png');
          URL.revokeObjectURL(url);
          resolve(base64);
        };

        img.onerror = () => {
          URL.revokeObjectURL(url);
          reject(new Error('Failed to load SVG'));
        };

        img.src = url;
      } catch (error) {
        reject(error);
      }
    });
  };

  // Helper function to add an SVG diagram to the PDF
  const addDiagramToPDF = async (
    doc: jsPDF, 
    svgString: string | undefined, 
    title: string,
    y: number
  ): Promise<number> => {
    if (!svgString) return y;

    try {
      const image = await svgToImage(svgString);
      const pageWidth = doc.internal.pageSize.width;
      const margin = 20;
      const maxWidth = pageWidth - (2 * margin);
      
      // Add title
      doc.setFontSize(14);
      doc.text(title, margin, y);
      
      // Add image
      doc.addImage(image, 'PNG', margin, y + 10, maxWidth, maxWidth * 0.6);
      
      // Return the new Y position
      return y + (maxWidth * 0.6) + 30;
    } catch (error) {
      console.error(`Error adding ${title} diagram:`, error);
      return y;
    }
  };

  const generatePDF = async () => {
    try {
      // Create new PDF document
      const doc = new jsPDF();
      
      // Set font
      doc.setFont('helvetica');
      
      // Title
      doc.setFontSize(20);
      doc.text('BEAM ANALYSIS REPORT', 20, 20);
      
      // Beam Properties Section
      doc.setFontSize(16);
      doc.text('Beam Properties', 20, 40);
      
      doc.setFontSize(12);
      const properties = [
        ['Length', `${formatValue(beamProperties?.length)} mm`],
        ['Young\'s Modulus (E)', `${formatValue(beamProperties?.E)} N/mm²`],
        ['Moment of Inertia (I)', `${formatValue(beamProperties?.I)} mm⁴`],
        ['Cross-sectional Area (A)', `${formatValue(beamProperties?.A)} mm²`]
      ];
      
      (doc as any).autoTable({
        startY: 45,
        head: [['Property', 'Value']],
        body: properties,
        theme: 'grid',
        headStyles: { fillColor: [69, 78, 89] }
      });

      // Reaction Forces Section
      doc.setFontSize(16);
      doc.text('Reaction Forces', 20, (doc as any).lastAutoTable.finalY + 20);

      const reactionForces = Object.entries(calculations?.reaction_forces || {}).map(([nodeId, forces]) => [
        nodeId,
        formatValue(forces?.Fx),
        formatValue(forces?.Fy),
        formatValue(forces?.M)
      ]);

      (doc as any).autoTable({
        startY: (doc as any).lastAutoTable.finalY + 25,
        head: [['Node', 'Fx (kN)', 'Fy (kN)', 'M (kNm)']],
        body: reactionForces,
        theme: 'grid',
        headStyles: { fillColor: [69, 78, 89] }
      });

      // Displacements Section
      doc.setFontSize(16);
      doc.text('Nodal Displacements', 20, (doc as any).lastAutoTable.finalY + 20);

      const displacements = Object.entries(calculations?.displacements || {}).map(([nodeId, disp]) => [
        nodeId,
        formatValue(disp?.ux),
        formatValue(disp?.uy),
        formatValue(disp?.phi)
      ]);

      (doc as any).autoTable({
        startY: (doc as any).lastAutoTable.finalY + 25,
        head: [['Node', 'ux (mm)', 'uy (mm)', 'φ (rad)']],
        body: displacements,
        theme: 'grid',
        headStyles: { fillColor: [69, 78, 89] }
      });

      // Element Forces Section
      doc.setFontSize(16);
      doc.text('Element Forces', 20, (doc as any).lastAutoTable.finalY + 20);

      const elementForces = Object.entries(calculations?.element_forces || {}).map(([elementId, forces]) => [
        elementId,
        Array.isArray(forces?.N) ? forces.N.map(formatValue).join(' → ') : '-',
        Array.isArray(forces?.V) ? forces.V.map(formatValue).join(' → ') : '-',
        Array.isArray(forces?.M) ? forces.M.map(formatValue).join(' → ') : '-'
      ]);

      (doc as any).autoTable({
        startY: (doc as any).lastAutoTable.finalY + 25,
        head: [['Element', 'N (kN)', 'V (kN)', 'M (kNm)']],
        body: elementForces,
        theme: 'grid',
        headStyles: { fillColor: [69, 78, 89] }
      });

      // Add diagrams if available
      if (svgs?.structure || svgs?.displacement || svgs?.bending_moment || svgs?.shear_force) {
        doc.addPage();
        doc.setFontSize(20);
        doc.text('Diagrams', 20, 20);

        let currentY = 40;

        // Add each diagram with titles
        currentY = await addDiagramToPDF(doc, svgs.structure, 'Structure', currentY);
        
        if (currentY > doc.internal.pageSize.height - 100) {
          doc.addPage();
          currentY = 20;
        }
        currentY = await addDiagramToPDF(doc, svgs.displacement, 'Displacement', currentY);
        
        if (currentY > doc.internal.pageSize.height - 100) {
          doc.addPage();
          currentY = 20;
        }
        currentY = await addDiagramToPDF(doc, svgs.bending_moment, 'Bending Moment', currentY);
        
        if (currentY > doc.internal.pageSize.height - 100) {
          doc.addPage();
          currentY = 20;
        }
        currentY = await addDiagramToPDF(doc, svgs.shear_force, 'Shear Force', currentY);
      }

      // Save the PDF
      doc.save('beam-analysis-report.pdf');

      toast({
        title: "Report Downloaded",
        description: "The analysis report has been downloaded successfully.",
      });
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast({
        title: "Error",
        description: "Failed to generate the report. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <Button
      onClick={generatePDF}
      className="flex items-center gap-2"
      variant="outline"
    >
      <FileText className="w-4 h-4" />
      Download Report
    </Button>
  );
};

export default PDFReport;