import { useMemo, useState } from 'react';
import { useAppSelector } from '@/store/hooks';
import { selectActiveBeamId } from '@/store/features/ui/uiSlice';
import { selectBeamById } from '@/store/features/beams/beamsSlice';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import LoadVisualization from './LoadVisualization';
import SupportSymbol from './SupportSymbols';

interface BeamVisualizationProps {
  height?: number;
}

const BeamVisualization = ({ height = 400 }: BeamVisualizationProps) => {
  const [loadState, setLoadState] = useState<'ULS' | 'SLS'>('ULS');
  const activeBeamId = useAppSelector(selectActiveBeamId);
  const beam = useAppSelector(state => 
    activeBeamId ? selectBeamById(state, activeBeamId) : null
  );

  const visualizationData = useMemo(() => {
    if (!beam) return null;

    
    const margin = { top: 100, right: 80, bottom: 60, left: 80 };
    const width = 1000;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    
    //  scale factor for the beam length
    const scaleFactor = innerWidth / beam.length;
    
    // Calculate center line Y position
    const centerY = margin.top + innerHeight / 2;

    // Calculate tick positions for dimensions
    const ticks = [
      0,
      beam.length / 2,
      beam.length
    ].map(pos => ({
      x: margin.left + pos * scaleFactor,
      value: pos
    }));

    return {
      width,
      height,
      scaleFactor,
      centerY,
      margin,
      ticks,
    };
  }, [beam, height]);

  if (!beam || !visualizationData) {
    return <div className="w-full h-full flex items-center justify-center bg-muted/5">
      <p className="text-sm text-muted-foreground">No beam selected</p>
    </div>;
  }

  const { width, scaleFactor, centerY, margin, ticks } = visualizationData;

  return (
    <div className="relative w-full h-full">
      {/* Load State Toggle */}
      <div className="w-fit">
        <ToggleGroup 
          type="single" 
          value={loadState} 
          onValueChange={(value) => value && setLoadState(value as 'ULS' | 'SLS')}
          className="bg-background border rounded-lg shadow-sm"
        >
          <ToggleGroupItem 
            value="ULS" 
            className="px-4 py-2 data-[state=on]:bg-primary data-[state=on]:text-primary-foreground"
          >
            ULS
          </ToggleGroupItem>
          <ToggleGroupItem 
            value="SLS"
            className="px-4 py-2 data-[state=on]:bg-primary data-[state=on]:text-primary-foreground"
          >
            SLS
          </ToggleGroupItem>
        </ToggleGroup>
      </div>

      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMid meet"
      >
        {/* Grid lines */}
        <g className="grid-lines" stroke="#f0f0f0" strokeWidth="1">
          {ticks.map(({ x }, i) => (
            <line
              key={`grid-${i}`}
              x1={x}
              y1={margin.top}
              x2={x}
              y2={height - margin.bottom}
              strokeDasharray="4,4"
            />
          ))}
        </g>

        {/* Beam centerline */}
        <line
          x1={margin.left}
          y1={centerY}
          x2={margin.left + beam.length * scaleFactor}
          y2={centerY}
          stroke="black"
          strokeWidth="3"
        />

        {/* Load Visualization */}
        <LoadVisualization
          pointLoads={beam.pointLoads}
          lineLoads={beam.lineLoads}
          scaleFactor={scaleFactor}
          centerY={centerY}
          margin={margin}
          loadState={loadState}
        />

<g className="supports">
  {beam.supports.map((support, index) => {
    const x = margin.left + support.location * scaleFactor;
    return (
      <g 
        key={`support-${index}`} 
        transform={`translate(${x},${centerY + 3})`}
      >
        <SupportSymbol 
          type={support.type} 
          size={25}  // Adjust size as needed
        />
      </g>
    );
  })}
</g>


        {/* Dimension line */}
        <g 
          className="dimension-line" 
          transform={`translate(0,${height - margin.bottom + 20})`}
        >
          <line
            x1={margin.left}
            x2={margin.left + beam.length * scaleFactor}
            y1="0"
            y2="0"
            stroke="black"
            strokeWidth="1"
            markerStart="url(#dimension-arrow)"
            markerEnd="url(#dimension-arrow)"
          />
          {ticks.map(({ x, value }) => (
            <g key={`tick-${value}`} transform={`translate(${x},0)`}>
              <line y2="5" stroke="black" strokeWidth="1" />
              <text
                y="20"
                textAnchor="middle"
                fontSize="12"
                fill="#666"
              >
                {value}
              </text>
            </g>
          ))}
        </g>
      </svg>
    </div>
  );
};

export default BeamVisualization;